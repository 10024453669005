<template>
  <div class="site-wrapper site-page--login">
    <div class="site-content__wrapper">
      <div class="site-content">
        <div class="title">
          <img src="@/assets/img/logo.png" alt="">
          <div>平台端</div>
        </div>
        <div class="login-main">
          <div class="label">
            <img src="@/assets/img/label.png" alt="">
            点赞积分制管理平台
          </div>
          <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
                   status-icon>
            <el-form-item prop="username" label="账号">
              <el-input v-model="dataForm.username" placeholder="请输入账号">
                <i slot="prefix" class="el-icon-user"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="passwordUn" label="密码">
              <el-input v-model="dataForm.passwordUn" type="password" placeholder="请输入密码">
                <i slot="prefix" class="el-icon-lock"></i>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="login-btn-submit" round :loading="btnLoading" type="primary"
                         @click="dataFormSubmit()">登 录
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getUUID
} from "@/utils";
import sha256 from 'sha256'

export default {
  data() {
    return {
      dataForm: {
        username: "",
        password: "",
        passwordUn: "",
        // uuid: "",
        // captcha: "",
        grant_type: "password",
      },
      dataRule: {
        username: [{
          required: true,
          message: "帐号不能为空",
          trigger: "blur",
        },],
        passwordUn: [{
          required: true,
          message: "密码不能为空",
          trigger: "blur",
        }],
        password: [{
          required: true,
          message: "密码不能为空",
          trigger: "blur",
        },],
        captcha: [{
          required: true,
          message: "验证码不能为空",
          trigger: "blur",
        },],
      },
      captchaPath: "",
      btnLoading: false,
    };
  },
  created() {
    this.$store.commit("common/updateIsAddRouter", false)
    this.$cookie.set("token", '');
  },
  methods: {
    // 提交表单
    dataFormSubmit() {
      this.$refs.dataForm.validate(async (valid) => {
        if (valid) {
          this.dataForm.password = this.dataForm.passwordUn
          this.btnLoading = true;
          let res = await this.$post(
              "/platform-auth/oauth/token",
              {
                username: this.dataForm.username,
                password: sha256(this.dataForm.passwordUn),
                grant_type: "password",
              },
              "",
              "login"
          );
          this.btnLoading = false;
          if (res && res.code === 0) {
            this.$cookie.set("token", res.access_token);
            this.getAuthList()
          } else {
            // this.getCaptcha();
            // return this.$message.error(res.msg);
          }
        }
      })
    },
    // 获得权限标识列表
    getAuthList() {
      this.$get('/platform-admin/menu/permissions').then(res => {
        if (res && res.code == 0) {
          localStorage.setItem("permissions", JSON.stringify(res.data))
          this.$router.replace({
            path: "/home",
          });
        }
      })
    },
    // 获取验证码
    getCaptcha() {
      this.dataForm.uuid = getUUID();
      this.captchaPath = `${this.$baseURL}/platform-auth/captcha?uuid=${this.dataForm.uuid}`;
    },
  }
}
</script>

<style lang="scss" scoped>
.site-wrapper.site-page--login {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(../../assets/img/bg.png);
  background-size: 100%;
  background-color: rgba(38, 50, 56, 0.6);
  overflow: hidden;

  &:before {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";
    // background-image: url(~@/assets/img/login_bg.jpg);
    // background-color: #ffffff;
    background-size: cover;
  }

  .site-content__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: transparent;
  }

  .site-content {
    min-height: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .title {
      width: 100%;
      padding: 16px 112px;
      background: rgba(255, 255, 255, .6);
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;

      img {
        width: 220px;
      }

      > div {
        font-size: 20px;
        color: #818181;
        margin-left: 49px;
        padding: 5px 0;
        padding-left: 36px;
        border-left: 1px solid #BCBCBC;
      }
    }

    .leftTopImg {
      position: absolute;
      top: -50vh;

      img {
        height: 98vh;
      }
    }

    .leftbottomImg {
      position: absolute;
      bottom: 0;

      img {
        height: 51.5vh;
      }

      .rightImg {
        height: 370px;
        vertical-align: bottom;
      }
    }

    .fromImg {
      position: absolute;
      top: 115px;
      left: 140px;

      img {
        width: 730px
      }
    }

    .login_bg5Box {
      position: absolute;
      top: 370px;
      left: 24px;

      img {
        width: 342px;
        height: 117px;
      }
    }

    .login_bg7Box {
      position: absolute;
      bottom: 220px;
      left: 620px;

      img {
        width: 320px;
      }
    }

    .login_bg9Box {
      position: absolute;
      bottom: 0;
      right: 30px;

      img {
        width: 100px;
      }
    }
  }

  .brand-info {
    margin: 220px 100px 0 90px;
    color: #fff;
  }

  .brand-info__text {
    margin: 0 0 22px 0;
    font-size: 48px;
    font-weight: 400;
    text-transform: uppercase;
  }

  .brand-info__intro {
    margin: 10px 0;
    font-size: 16px;
    line-height: 1.58;
    opacity: 0.6;
  }

  .login-main {
    padding: 58px 68px;
    width: 570px;
    height: 470px;
    background-color: #fff;
    // background-image: url(../../assets/img/bg.png);
    background-size: 650px 870px;
    background-repeat: no-repeat;
    background-position: 0px -34px;
    border-radius: 20px;

    .label {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #1A1A1A;
      margin-bottom: 20px;

      img {
        width: 4px;
        margin-right: 7px;
      }
    }
  }

  .login-title {
    font-size: 16px;
  }

  .login-captcha {
    overflow: hidden;

    > img {
      width: 100%;
      cursor: pointer;
    }
  }

  .login-btn-submit {
    width: 100%;
    margin-top: 38px;
    font-size: 18px;
    background: linear-gradient(58deg, #1dbbf6 14%, #34a2f1 83%);
    box-shadow: 0px 3px 10px 0px rgba(96, 186, 255, 0.35);
  }
}


/deep/ .el-input__prefix {
  font-size: 18px;
  padding: 0px 10px;
}

/deep/ .el-input--prefix .el-input__inner {
  padding-left: 70px;
}

/deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  display: none;
}

/deep/ .el-form-item__label {
  font-size: 16px;
}
</style>
